<p-button
  size="small"
  label="Creează locker"
  icon="pi pi-plus"
  iconPos="left"
  (click)="confirmLockerCreate()"
></p-button>

<form class="grid mt-1" [formGroup]="filtersForm">
  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-1">Lockere</span>
    <p-multiSelect
      formControlName="lockerIdentifiers"
      optionLabel="name"
      optionValue="id"
      styleClass="w-full"
      appendTo="body"
      placeholder="Caută lockere"
      [showClear]="true"
      [options]="lockers()"
      [loading]="!lockers()"
    >
      <ng-template let-locker pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            <small class="text-gray-400">#{{ locker?.id }}</small>
            <p class="m-0">{{ locker?.name }}</p>
          </div>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>

  <div class="flex align-items-center gap-2 col-12 lg:col-3">
    <p-checkbox
      class="mt-1"
      formControlName="isActive"
      label="Active"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      class="mt-1"
      formControlName="isConfigured"
      label="Configurate"
      [binary]="true"
    ></p-checkbox>

    <p-checkbox
      class="mt-1"
      [formControl]="autoRefresh"
      label="Auto refresh"
      [binary]="true"
    ></p-checkbox>
  </div>
</form>

<p-table
  styleClass="p-datatable-sm w-full"
  currentPageReportTemplate="{first} - {last} din {totalRecords} lockere"
  paginatorPosition="both"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="data()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr>
        <td colspan="7">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th>Locker</th>
      <th class="text-center">Versiune soft</th>
      <th class="text-center">Structură</th>
      <th class="text-center">Evenimente</th>
      <th class="text-center">Număr de restarturi</th>
      <th class="text-center">Extras</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-locker>
    <tr
      class="hover:surface-200 cursor-pointer"
      (click)="openLockerDetails(locker)"
    >
      <td>
        <div class="flex flex-column">
          <div>
            <small class="text-gray-400">#{{ locker.externalIdentifier }}</small><br>
            <b>{{ locker.name }}</b>
            <br>
            <small>{{ formatLockerAddress(locker.addressParts) }}</small>
          </div>
          <div class="flex align-items-center gap-1">
            <p-tag
              [value]="locker.state.isConfigured ? 'Configurat' : 'Neconfigurat'"
              [severity]="locker.state.isConfigured ? 'success' : 'danger'"
            ></p-tag>
            <p-tag
              [value]="locker.state.isOnline ? 'Online' : 'Offline'"
              [severity]="locker.state.isOnline ? 'success' : 'danger'"
            ></p-tag>
            <p-tag
              [value]="locker.state.isActive ? 'Activ' : 'Inactiv'"
              [severity]="locker.state.isActive ? 'success' : 'danger'"
            ></p-tag>
          </div>
        </div>
      </td>
      <td
        class="text-center"
        [class.text-red-500]="!locker.softwareVersion.isLatest"
      >{{ locker.softwareVersion.name }}({{ locker.softwareVersion.code }})</td>
      <td class="text-center">
        <p class="m-0">Echipamente: {{ locker.equipments.count }}</p>
        <p class="m-0">Module: {{ locker.modules.count }}</p>
      </td>
      <td class="text-center">
        {{ locker?.recentProcedures?.totalCount }} total
        /
        {{ locker?.recentProcedures?.taggedCount }} tag
      </td>
      <td class="text-center" [class.text-red-500]="locker?.restarts?.count > 0">{{ locker?.restarts?.count }}</td>
      <td class="text-center">
        <p class="m-0">
          {{ locker?.heartbeat?.count }}
          /
          {{ formatTimeInMiliseconds(locker?.heartbeat?.data?.uptime) }}
          @if (locker?.heartbeat?.lastPingAt) {
            /
            <!-- Pass 2 to the function to check if 3 minutes has passed -->
            <span
              [class.text-red-500]="lockerExtras()[locker.externalIdentifier]?.isOldHeartbeat"
            >{{ formatDate(locker?.heartbeat?.lastPingAt) }}</span>
          }
        </p>
        @if (locker?.heartbeat?.data?.memory) {
          <p
            [class.text-red-500]="lockerExtras()[locker.externalIdentifier]?.memoryFreePercentage >= 0 && lockerExtras()[locker.externalIdentifier]?.memoryFreePercentage <= 20"
            [class.text-orange-500]="lockerExtras()[locker.externalIdentifier]?.memoryFreePercentage > 20 && lockerExtras()[locker.externalIdentifier]?.memoryFreePercentage <= 60"
            [class.text-green-500]="lockerExtras()[locker.externalIdentifier]?.memoryFreePercentage > 60"
            class="m-0"
          >
            Memory:
            {{ formatBytes(locker?.heartbeat?.data?.memory?.total - locker?.heartbeat?.data?.memory?.available) }} used,
            {{ formatBytes(locker?.heartbeat?.data?.memory?.available) }} free
            /
            {{ formatBytes(locker?.heartbeat?.data?.memory?.total) }}
          </p>
        }
        @if (locker?.heartbeat?.data?.storage) {
          <p
            [class.text-red-500]="lockerExtras()[locker.externalIdentifier].storageFreePercentage >= 0 && lockerExtras()[locker.externalIdentifier].storageFreePercentage <= 20"
            [class.text-orange-500]="lockerExtras()[locker.externalIdentifier].storageFreePercentage > 20 && lockerExtras()[locker.externalIdentifier].storageFreePercentage <= 60"
            [class.text-green-500]="lockerExtras()[locker.externalIdentifier].storageFreePercentage > 60"
            class="m-0"
          >
            Storage:
            {{ formatBytes(locker?.heartbeat?.data?.storage?.total - locker?.heartbeat?.data?.storage?.available) }} used,
            {{ formatBytes(locker?.heartbeat?.data?.storage?.available) }} free
            /
            {{ formatBytes(locker?.heartbeat?.data?.storage?.total) }}
          </p>
        }
        <p class="m-0">
          <span
            [class.text-red-500]="!locker?.heartbeat?.data?.messageBroker?.isReady"
            [class.text-green-500]="locker?.heartbeat?.data?.messageBroker?.isReady"
          >Broker: {{ locker?.heartbeat?.data?.messageBroker?.isReady ? "Activ" : "Inactiv" }}</span>
          /
          Evenimente cu eroare:
          <span [class.text-red-500]="locker?.heartbeat?.data?.events?.failedCount > 0">{{ locker?.heartbeat?.data?.events?.failedCount }}</span>
        </p>
      </td>
    </tr>
  </ng-template>
</p-table>