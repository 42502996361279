<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Update {{ lockerIdentifier }}</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<div mat-dialog-content>
  Sunteți sigur că doriți să actualizați lockerul?
</div>
<div class="flex align-items-center gap-2" mat-dialog-actions align="end">
  <p-button
    label="Anulează"
    [text]="true"
  ></p-button>
  <p-button
    label="Confirmă"
    [mat-dialog-close]="true"
  ></p-button>
</div>