import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-cbox-profile-locker-update-confirmation-dialog',
  templateUrl: './cbox-profile-locker-update-confirmation-dialog.component.html',
  styleUrls: ['./cbox-profile-locker-update-confirmation-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogContent,
    MatDialogClose,
    MatDialogActions,
    ButtonModule
  ]
})

export class CBoxProfileLockerUpdateConfirmationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public lockerIdentifier: string) { }

  ngOnInit() { }
}