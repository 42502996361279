import { CommonModule } from "@angular/common";
import { Component, DestroyRef, Injector, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CBox_GetUserListRequestParams, CBox_GetUserListResponseItem } from "@server/services/cbox/public/api/v1/resources/user/types";
import { MenuItem } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { TableModule } from "primeng/table";
import { ProfileService } from "src/services/profile/profile.service";
import { SessionService } from "src/services/session/session.service";
import { CBoxProfilePlatformUserCreateComponent } from "./dialogs/create/default/cbox-profile-platform-user-create.component";
import { MatDialog } from "@angular/material/dialog";
import { first } from "rxjs";
import { ProgressBarModule } from "primeng/progressbar";
import { MenuModule } from "primeng/menu";
import { CBoxProfilePlatformUserUpdateComponent } from "./dialogs/update/cbox-profile-platform-user-update.component";
import { CBoxProfilePlatformUserPasswordResetConfirmDialogComponent } from "./dialogs/password-reset-confirm/cbox-profile-platform-user-password-reset-confirm-dialog.component";
import { PermissionNamesEnum } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { PermissionDirective } from "src/directives/permission.directive";
import { CBoxProfilePlatformUserDeleteConfirmDialogComponent } from "./dialogs/delete/cbox-profile-platform-user-delete-confirm-dialog.component";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { UserService } from "src/services/user/user.service";
import { TableWithFilters } from "app/shared/table-with-filters";
import { CBoxProfilePlatformSearchableUserCreateComponent } from "./dialogs/create/searchable/cbox-profile-platform-searchable-user-create.component";
import { CBox_PublicConfigurationIdentifierField } from "@server/services/cbox/public/api/v1/enforcers/company_config/types";

@Component({
  selector: "app-cbox-profile-platform-users-list",
  templateUrl: "cbox-profile-platform-users-list.component.html",
  styleUrls: ["./cbox-profile-platform-users-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    ProgressBarModule,
    MenuModule,
    PermissionDirective,
    IconFieldModule,
    InputIconModule
  ]
})

export class CBoxProfilePlatformUsersListComponent extends TableWithFilters<CBox_GetUserListResponseItem, CBox_GetUserListRequestParams> {

  filtersForm: FormGroup;
  zones = signal<{id: number; name: string}[]>([]);
  permissions = PermissionNamesEnum;
  currentUser = signal<CBox_GetUserListResponseItem | undefined>(undefined);
  dataFetchUrl = "users";
  sessionFiltersKey = "profile/users/platform/filters";
  sessionFiltersToSave = ["email", "phone", "page"];
  localStorageFiltersKey = "profile/users/platform/filters";
  localStorageFiltersToSave = ["pageSize"];
  defaultFilters = {
    name: "",
    email: "",
    phone: "",
    page: 1,
    pageSize: 25,
  };

  usersMenuItems = signal<Record<string, MenuItem[]>>({});
  scrollPosition = signal(0);

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private session: SessionService,
    private dialog: MatDialog,
    private configuration: ConfigurationService,
    private userService: UserService) {
      super(inj);
      this.filtersForm = this.fb.group({
        name: [""],
        email: [""],
        phone: [""],
        page: [1],
        pageSize: [25]
      });
    }

  ngOnInit(): void {
    this.baseInit();
  }

  async init(): Promise<void> {
    this.profileService.setTitle("Listă utilizatori");
    this.zones.set(await this.configuration.getAllowedZones());
    const currentUserEmail = (await this.userService.getData()).data?.email;
      this.currentUser.set(this.data().find((user) => user.email === currentUserEmail));
  }

  async createUser(): Promise<void> {
    const isSearchable = (await this.configurationService.getConfigKey("user.identifier.requirements") as CBox_PublicConfigurationIdentifierField).isSearchable;
    console.log(isSearchable, "Searchable");
    this.scrollPosition.set(window.scrollY);
    window.scrollTo(0, 0);
    if (isSearchable) {
      const dialog = this.dialog.open(CBoxProfilePlatformSearchableUserCreateComponent, {
        width: "min(600px, 100%)",
        disableClose: true,
        autoFocus: false
      });

      dialog.afterClosed().pipe(first()).subscribe((created: boolean) => {
        if (created) {
          this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost adăugat cu succes.");
          this.loading.set(true);
          this.fetchData();
        }
        window.scrollTo(0, this.scrollPosition());
      });
    } else {
      const dialog = this.dialog.open(CBoxProfilePlatformUserCreateComponent, {
        width: "min(600px, 100%)",
        disableClose: true,
        autoFocus: false
      });

      dialog.afterClosed().pipe(first()).subscribe((created: boolean) => {
        if (created) {
          this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost adăugat cu succes.");
          this.loading.set(true);
          this.fetchData();
        }
        window.scrollTo(0, this.scrollPosition());
      });
    }
  }

  updateUser(user: CBox_GetUserListResponseItem | undefined): void {
    if (!user) {
      return;
    }

    this.scrollPosition.set(window.scrollY);
    window.scrollTo(0, 0);
    const dialog = this.dialog.open(CBoxProfilePlatformUserUpdateComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false,
      data: user.identifier
    });

    dialog.afterClosed().pipe(first()).subscribe((edited: boolean) => {
      if (edited) {
        this.toastService.showSuccessToast("Confirmare", "Utilizatorul a fost editat cu succes.");
        this.loading.set(true);
        this.fetchData();
      }
      window.scrollTo(0, this.scrollPosition());
    });
  }

  resetUserPassword(user: CBox_GetUserListResponseItem | undefined): void {
    if (!user) {
      return;
    }

    this.scrollPosition.set(window.scrollY);
    window.scrollTo(0, 0);
    const dialog = this.dialog.open(CBoxProfilePlatformUserPasswordResetConfirmDialogComponent, {
      data: user,
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      window.scrollTo(0, this.scrollPosition());
    });
  }

  confirmUserDelete(user: CBox_GetUserListResponseItem | undefined): void {
    if (!user) {
      return;
    }

    this.scrollPosition.set(window.scrollY);
    window.scrollTo(0, 0);
    const dialog = this.dialog.open(CBoxProfilePlatformUserDeleteConfirmDialogComponent, {
      data: user,
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
      window.scrollTo(0, this.scrollPosition());
    });
  }

  getZoneName(zoneId: number): string {
    return this.zones().find((zone) => +zone.id === zoneId)?.name || "";
  }

  getUserMenuItems(user: CBox_GetUserListResponseItem): MenuItem[] {
    return [
      {
        label: "Resetează parolă",
        icon: "pi pi-refresh",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.resetPlatformUserPassword]),
        command: () => {
          this.resetUserPassword(user);
        }
      },
      {
        label: "Editează utilizator",
        icon: "pi pi-user-edit",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.editPlatformUser]),
        command: () => {
          this.updateUser(user);
        }
      },
      {
        label: "Șterge utilizator",
        icon: "pi pi-trash",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.deletePlatformUser]),
        command: () => {
          this.confirmUserDelete(user);
        }
      }
    ];
  }

  openMenu(user: CBox_GetUserListResponseItem): void {
    const items =  [
      {
        label: "Resetează parolă",
        icon: "pi pi-refresh",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.resetPlatformUserPassword]),
        command: () => {
          this.resetUserPassword(user);
        }
      },
      {
        label: "Editează utilizator",
        icon: "pi pi-user-edit",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.editPlatformUser]),
        command: () => {
          this.updateUser(user);
        }
      },
      {
        label: "Șterge utilizator",
        icon: "pi pi-trash",
        visible: this.configurationService.hasAccess([PermissionNamesEnum.deletePlatformUser]),
        command: () => {
          this.confirmUserDelete(user);
        }
      }
    ];

    this.usersMenuItems.update(rows => {
      rows[user.identifier] = items.filter(item => item.visible);
      return rows;
    });
  }

  menuClosed(agent: CBox_GetUserListResponseItem): void {
    this.usersMenuItems.update(rows => {
      delete rows[agent.identifier];
      return rows;
    });
  }

  getSearchStructure() {
    return Object.entries({
      name: this.filtersForm.value.name,
      phone: this.filtersForm.value.phone,
      email: this.filtersForm.value.email,
      page: this.filtersForm.value.page,
      pageSize: this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_GetUserListRequestParams] = value;
      return acc;
    }, {} as any);
  }
}