import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Injector, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CBox_PublicGetReportFileResponse, CBox_PublicGetReportListDataParams, CBox_PublicGetReportListItemData, CBox_PublicReportType } from '@server/services/cbox/public/api/v1/resources/reports/types';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { first } from 'rxjs';
import { handlePublicApiError } from 'src/services/api/api.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { fileDownloadHelper } from 'src/helpers/file-download';
import { CBoxProfileReportGenerateControllerComponent } from '../dialogs/generate/controller/cbox-profile-report-generate-controller.component';
import { CBoxProfileAgentsActivityReportGenerateDialogComponent } from '../dialogs/generate/agents-activity/cbox-profile-agents-activity-report-generate-dialog.component';
import { CBoxProfileLockersActivityReportGenerateDialogComponent } from '../dialogs/generate/lockers-activity/cbox-profile-lockers-activity-report-generate-dialog.component';
import { TableWithFilters } from 'app/shared/table-with-filters';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { formatDate } from 'src/helpers/functions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-cbox-profile-generated-reports',
  templateUrl: './cbox-profile-generated-reports.component.html',
  styleUrls: ["./cbox-profile-generated-reports.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    CalendarModule,
    CheckboxModule,
    MultiSelectModule,
    TableModule,
    MenuModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    TagModule
  ]
})

export class CBoxProfileGeneratedReportsComponent extends TableWithFilters<CBox_PublicGetReportListItemData, CBox_PublicGetReportListDataParams> implements OnInit {
  filtersForm: FormGroup;
  reports = signal<CBox_PublicGetReportListItemData[]>([]);
  reportTypeTranslations = signal<Record<string, string>>({
    [CBox_PublicReportType.agentActivity]: "Activitate agenti",
    [CBox_PublicReportType.lockerActivity]: "Activitate lockere"
  });
  reportTypes = signal<{
    type: CBox_PublicReportType,
    label: string,
  }[]>([
    {
      type: CBox_PublicReportType.agentActivity,
      label: "Activitate agenti"
    },
    {
      type: CBox_PublicReportType.lockerActivity,
      label: "Activitate lockere"
    }
  ]);
  reportStatesTranslations = signal<Record<string, string>>({
    PENDING: "În așteptare",
    IN_PROGRESS: "In generare",
    PENDING_RETRY: "În așteptare",
    FINISHED: "Finalizat",
    ERRORED: "Eroare",
    CANCELED: "Anulat"
  });
  reportStates = signal<{
    state: string,
    label: string,
    severity: "success" | "secondary" | "info" | "warning" | "contrast" | "danger" | undefined
  }[]>([
    {
      state: "PENDING",
      label: "În așteptare",
      severity: "secondary"
    },
    {
      state: "IN_PROGRESS",
      label: "In generare",
      severity: "info"
    },
    {
      state: "PENDING_RETRY",
      label: "În așteptare",
      severity: "info"
    },
    {
      state: "FINISHED",
      label: "Finalizat",
      severity: "success"
    },
    {
      state: "ERRORED",
      label: "Eroare",
      severity: "danger"
    },
    {
      state: "CANCELED",
      label: "Anulat",
      severity: "danger"
    }
  ]);
  downloadingItems = signal<{ identifier?: string, type?: "pdf" | "xlsx" }[]>([]);
  dataFetchUrl = "reports";
  sessionFiltersKey = "profile/reports/generated/filters";
  sessionFiltersToSave = ["page", "pageSize", "interval", "types", "agents"];
  localStorageFiltersKey = "profile/reports/generated/filters";
  localStorageFiltersToSave = [];
  defaultFilters = {
    page: 1,
    pageSize: 25,
    interval: [this.oneMonthAgo, this.today],
    types: [CBox_PublicReportType.agentActivity, CBox_PublicReportType.lockerActivity],
    agents: []
  };

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private dialog: MatDialog) {
      super(inj);
      this.filtersForm = this.fb.group({
        page: [1],
        pageSize: [25],
        interval: [[this.oneMonthAgo, this.today]],
        types: [[0]],
        agents: [[CBox_PublicReportType.agentActivity, CBox_PublicReportType.lockerActivity]]
      });
    }

  ngOnInit(): void {
    super.baseInit();
    this.reportsService.onUpdate().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.fetchData();
    });
  }

  async init(): Promise<void> {
    this.profileService.setTitle("Listă lockere");
  }

  downloadReport(report: CBox_PublicGetReportListItemData, type: "pdf" | "xlsx"): void {
    this.downloadingItems.update(items => {
      items.push({
        identifier: report.identifier,
        type: type
      });
      return items;
    });

    this.api
        .get<CBox_PublicSuccessResponse<CBox_PublicGetReportFileResponse>>(
            `backend/reports/${report.identifier}/file?fileType=${type}`)
        .subscribe(
            (response) => {
              const type = report.type === CBox_PublicReportType.agentActivity ? "raport-activitate-agent" : "raport-activitate-locker";
              const filename = `${formatDate(new Date(report.interval.start))}_${formatDate(new Date(report.interval.end))}_${type}`;
              fileDownloadHelper(
                  response.data.content as any,
                  response.data.type.toLowerCase(),
                  filename + "." + response.data.type.toLowerCase());
              this.toastService.showSuccessToast(
                  'Confirmare', 'Raportul a fost descărcat cu succes');
              this.downloadingItems.update(
                  items => items.filter(
                      report => report.identifier !== report.identifier));
            },
            (e: HttpErrorResponse) => {
              handlePublicApiError(e, this.toastService);
              this.downloadingItems.update(
                  items => items.filter(
                      report => report.identifier !== report.identifier));
            });
  }

  isDownloading(report: CBox_PublicGetReportListItemData, type: "pdf" | "xlsx"): boolean {
    return !!this.downloadingItems()?.find(item => item.identifier === report.identifier && item.type === type);
  }

  getReportSeverity(state: string): "success" | "secondary" | "info" | "warning" | "contrast" | "danger" | undefined {
    const reportState = this.reportStates()?.find(reportState => reportState.state === state);
    return reportState?.severity || "secondary";
  }

  generateReport(): void {
    const dialog = this.dialog.open(CBoxProfileReportGenerateControllerComponent, {
      width: "min(600px, 100%)"
    });

    dialog.afterClosed().pipe(first()).subscribe((reportType: "lockers" | "agents") => {
      let dialog;
      if (reportType) {
        switch (reportType) {
          case "agents": {
            dialog = this.dialog.open(CBoxProfileAgentsActivityReportGenerateDialogComponent, {
              width: "min(600px, 100%)",
              data: {
                interval: this.filtersForm.value.interval
              }
            });
            break;
          }

          case "lockers": {
            dialog = this.dialog.open(CBoxProfileLockersActivityReportGenerateDialogComponent, {
              width: "min(600px, 100%)",
              data: {
                interval: this.filtersForm.value.interval
              }
            });
            break;
          }
        }
      }
      dialog?.afterClosed().pipe(first()).subscribe(() => {
        this.loading.set(true);
        this.fetchData();
      });
    });
  }

  override async fetchData(): Promise<void> {
    try {
      const reports = await this.reportsService.getPaginatedReports(this.getSearchStructure());
      console.log(reports, "REPORTS IN OVERRITEN FETCH DATA");
      this.data.set(reports.data);
      this.totalCount.set(reports.totalCount);
      this.loading.set(false);
    } catch (e: any) {
      this.reportsService.retryFetchingActiveReports();
      handlePublicApiError(e, this.toastService);
      this.loading.set(false);
    }
  }

  getSearchStructure() {
    let interval = this.filtersForm.value.interval;
    if (typeof interval === "string") {
      interval = interval.split(",");
    }

    return Object.entries({
      "lockers[]": this.filtersForm.value.lockers,
      "agents[]": this.filtersForm.value.agents,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize,
      startDate: interval[0] ? new Date(new Date(interval[0]).setHours(0)).toISOString() : this.oneMonthAgo,
      endDate: interval[1] ? new Date(new Date(interval[1]).setHours(23, 59, 59, 599)).toISOString() : this.today,
      "types[]": this.filtersForm.value.types,
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_PublicGetReportListDataParams] = value;
      return acc;
    }, {} as any);
  }
}