<div class="dialog-header flex align-items-center justify-content-between w-full">
  <div class="flex flex-1 justify-content-between align-items-center">
    <div class="flex flex-column">
      <h2>Detalii {{ lockerIdentifier }}</h2>
      <div class="flex align-items-center gap-1">
        <p-tag
          [value]="lockerData()?.state?.isConfigured ? 'Configurat' : 'Neconfigurat'"
          [severity]="lockerData()?.state?.isConfigured ? 'success' : 'danger'"
        ></p-tag>
        <p-tag
          [value]="lockerData()?.state?.isOnline ? 'Online' : 'Offline'"
          [severity]="lockerData()?.state?.isOnline ? 'success' : 'danger'"
        ></p-tag>
        <p-tag
          [value]="lockerData()?.state?.isActive ? 'Activ' : 'Inactiv'"
          [severity]="lockerData()?.state?.isActive ? 'success' : 'danger'"
        ></p-tag>
      </div>
    </div>
    @if (lockerData() && !lockerData()?.softwareVersion?.isLatest) {
      <p-button
        mat-dialog-close
        label="Update"
        size="small"
        icon="pi pi-sync"
        styleClass="mr-4"
      />
    }
  </div>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  @if (!loading()) {
    <div class="grid">
      <div class="col-12 md:col-6">
        <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
          <app-cbox-to-do-list
            [lockerIdentifiers]="[lockerIdentifier]"
          ></app-cbox-to-do-list>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
          <h2 class="m-0 mb-2">Configurare</h2>
          <div class="flex align-items-center justify-content-center">
          @if (dataFetched()["lockerData"]) {
            <img class="max-w-15rem" [src]="lockerData()?.internalIdentifier?.image">
          } @else {
            <p-progressSpinner></p-progressSpinner>
          }
          </div>
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
          <h2 class="m-0 mb-2">Module</h2>
          @for (module of modules(); track $index) {
            <div
              class="
                flex flex-nowrap justify-content-between align-items-center border-1
                surface-border border-round py-3 px-3 cursor-pointer select-none hover:surface-hover
                transition-colors transition-duration-150
              "
              (click)="openModuleDataDialog(module.id)"
            >
              <div class="flex flex-column flex-nowrap">
                <p class="m-0 text-lg">
                  <b>{{ module.serial }}</b>
                </p>
                <p class="text-gray-400 m-0">
                  {{ module.type.description }}
                </p>
                <!-- @if (modulesData()[module.id]) {
                  @let necesarryEquipments = getModuleNecesarryEquipmentsCount(module.id);
                  @if (!!necesarryEquipments) {
                    <p class="text-red-500 m-0 -mt-1 text-xs">{{ necesarryEquipments < 2 ? "Un" : necesarryEquipments}} {{ necesarryEquipments < 2 ? "echipament necesar" : "echipamente necesare" }}</p>
                  }
                } -->
              </div>
              @if (modulesData()[module.id]) {
                @let moduleData = modulesData()[module.id];
                <div class="flex flex-nowrap gap-2 align-items-center">
                  @if (!!getModuleNecesarryEquipmentsCount(module.id)) {
                    <b class="text-red-500">{{ getModuleNecesarryEquipmentsCount(module.id) }}</b>
                    <span class="material-symbols-rounded text-red-500">priority_high</span>
                  } @else {
                    @if (moduleData?.equipments?.length) {
                      <b>{{ moduleData?.equipments?.length }}</b>
                      <span class="material-symbols-rounded">build</span>
                    }
                  }
                </div>

              } @else {
                <p-progressSpinner
                  [style]="{
                    width: '1rem',
                    height: '1rem',
                  }"
                ></p-progressSpinner>
              }
            </div>
          }
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
          <h2 class="m-0 mb-2">Echipamente</h2>
          @if (equipmentsReady()) {
            <p-table
              styleClass="p-datatable-sm p-datatable-striped"
              [value]="equipments()"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Tip</th>
                  <th>Serie</th>
                  <th>Modul</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-equipment>
                <tr>
                  <td>{{equipment.type.name}}</td>
                  <td>{{ equipment.internalSerial }}</td>
                  <td>{{ equipment.module.serial }}</td>
                  <td class="text-right">
                    <button
                      pButton
                      size="small"
                      class="py-1 px-2 text-xs white-space-nowrap"
                      (click)="createEquipmentToDo(equipment)"
                    >
                      <span class="material-symbols-rounded md-18">add</span>
                      <span class="font-bold">To do</span>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="3">
                    Nu există echipamente.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          } @else {
            <p-skeleton
              styleClass="w-full"
              height="10rem"
              borderRadius=".5rem"
            ></p-skeleton>
          }
        </div>
      </div>

      @if (lockerData()?.heartbeat?.data?.memory) {
        <div class="col-12 md:col-6">
          <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
            <h2 class="m-0 mb-2">RAM</h2>
            <p-meterGroup [value]="memoryMeterItems()" />
          </div>
        </div>
      }

      @if (lockerData()?.heartbeat?.data?.storage) {
        <div class="col-12 md:col-6">
          <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
            <h2 class="m-0 mb-2">HDD</h2>
            <p-meterGroup [value]="storageMeterItems()" />
          </div>
        </div>
      }

      <div class="col-12 md:col-6">
        <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
          <div class="flex align-items-center gap-2 mb-2">
            <h2 class="m-0">Adresă</h2>
            <span
              class="material-symbols-rounded cursor-pointer hover:opacity-50"
              (click)="configLocker()"
            >edit</span>
          </div>
          @if (dataFetched()["lockerData"]) {
            <p>{{ lockerCleanAddress() }}</p>
          } @else {
            <p-progressSpinner></p-progressSpinner>
          }
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card h-full shadow-1 border-round flex flex-column align-items-between gap-2 p-4">
          <h2 class="m-0">Heartbeat</h2>
          <p class="m-0">
            Count: {{ lockerData()?.heartbeat?.count }}
            /
            Uptime: {{ formatTimeInMiliseconds(lockerData()?.heartbeat?.data?.uptime) }}
            @if (lockerData()?.heartbeat?.lastPingAt) {
              /
              <!-- Pass 2 to the function to check if 3 minutes has passed -->
              Last ping at: <span
                [class.text-red-500]="lockerExtras()?.isOldHeartbeat"
              >{{ formatDate(lockerData()?.heartbeat?.lastPingAt) }}</span>
            }
          </p>
          <p class="m-0">
            <span
              [class.text-red-500]="!lockerData()?.heartbeat?.data?.messageBroker?.isReady"
              [class.text-green-500]="lockerData()?.heartbeat?.data?.messageBroker?.isReady"
            >Broker: {{ lockerData()?.heartbeat?.data?.messageBroker?.isReady ? "Activ" : "Inactiv" }}</span>
            /
            Evenimente cu eroare:
            <span [class.text-red-500]="lockerData()?.heartbeat?.data?.events?.failedCount && lockerData()?.heartbeat?.data?.events?.failedCount! > 0">{{ lockerData()?.heartbeat?.data?.events?.failedCount }}</span>
          </p>
        </div>
      </div>
    </div>
  }
</mat-dialog-content>
