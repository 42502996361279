import { CommonModule, formatDate } from '@angular/common';
import { Component, DestroyRef, Inject, InjectionToken, Injector, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { CBoxAdminLockerCreateConfirmDialogComponent } from '../dialogs/create/cbox-admin-locker-create-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, first, map, tap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProfileService } from 'src/services/profile/profile.service';
import { formatBytes, formatTimeInMiliseconds, hasMinutesPassedSince } from 'src/helpers/functions';
import { TagModule } from 'primeng/tag';
import { CBoxAdminLockerConfigDialogComponent } from '../dialogs/config/cbox-admin-locker-config-dialog.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CBox_AdminLockerData, CBox_GetAdminLockerListDataItem } from '@server/services/cbox/public/api/v1/resources/internal/locker/types';
import { CheckboxModule } from 'primeng/checkbox';
import { CBoxAdminLockerInfoComponent } from '../dialogs/info/cbox-admin-locker-info.component';
import { TableWithFilters } from 'app/shared/table-with-filters';
import { CBox_GetLockerListDataParams } from '@server/services/cbox/public/api/v1/resources/locker/types';

type ExtendedDataTypeItem = CBox_GetAdminLockerListDataItem & {
  isOldHeartbeat: boolean;
  storageFreePercentage: number;
  memoryFreePercentage: number;
}

@Component({
  selector: 'app-cbox-admin-lockers-list',
  templateUrl: './cbox-admin-lockers-list.component.html',
  styleUrls: ["./cbox-admin-lockers-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ProgressBarModule,
    TagModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CheckboxModule
  ]
})

export class CBoxAdminLockersListComponent extends TableWithFilters<CBox_GetAdminLockerListDataItem, CBox_GetLockerListDataParams> implements OnInit {

  filtersForm: FormGroup;
  sessionFiltersKey = "lockers/admin/list";
  localStorageFiltersKey = "lockers/admin/list";
  sessionFiltersToSave = ["lockerIdentifiers", "pageSize", "page", "includePickupCount", "includeDropoffCount", "isActive", "isConfigured"];
  localStorageFiltersToSave = [];
  dataFetchUrl = "internal/lockers";
  defaultFilters = {
    lockerIdentifiers: [],
    pageSize: 100,
    page: 1,
    includePickupCount: false,
    includeDropoffCount: false,
    isActive: false,
    isConfigured: false
  };
  lockerExtras = signal<Record<string, any>>({});

  refreshInterval?: NodeJS.Timeout;
  autoRefresh = new FormControl(false);

  constructor(
    private inj: Injector,
    private profile: ProfileService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>){
      super(inj);
      this.profile.setTitle("Listă lockere admin");
      this.filtersForm = this.fb.group({
        lockerIdentifiers: [[]],
        pageSize: [100], // default 100
        page: [1], // default 1
        includePickupCount: [false],
        includeDropoffCount: [false],
        isActive: [false],
        isConfigured: [false]
      });
  }

  ngOnInit(): void {
    this.init();
  }

  openLockerDetails(locker: CBox_GetAdminLockerListDataItem): void {
    const dialog = this.dialog.open(CBoxAdminLockerInfoComponent, {
      data: locker.externalIdentifier,
      minWidth: "min(1200px, 100%)"
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  confirmLockerCreate(): void {
    const dialog = this.dialog.open(CBoxAdminLockerCreateConfirmDialogComponent, {
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  editLocker(locker: CBox_AdminLockerData | undefined): void {
    if (!locker) {
      return;
    }
    const dialog = this.dialog.open(CBoxAdminLockerConfigDialogComponent, {
      data: locker,
      width: "min(600px, 100%)",
      disableClose: true,
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.fetchData();
    });
  }

  async init(): Promise<void> {
    this.lockers.set(await this.lockersService.getList());
    this.autoRefresh.valueChanges.pipe(
      tap(() => {
        clearInterval(this.refreshInterval);
      }),
      filter((autoRefresh) => !!autoRefresh),
    ).subscribe(() => {
      this.refreshInterval = setInterval(() => {
        console.log("RUNNING INTERVAL");
        this.loading.set(true);
        this.fetchData();
      }, 15000);
    })
    // this.autoRefresh.valueChanges.pipe(first())
    //     .subscribe((autoRefresh) => {
    //       console.log(autoRefresh, "AUTOREFRESH");
    //       if (autoRefresh) {
    //         // 15 seconds fetch
    //         this.refreshInterval = setInterval(() => {
    //           this.loading.set(true);
    //           this.fetchData();
    //         }, 15000);
    //       } else {
    //         console.log("CLEARING INTERVAL");
    //         clearInterval(this.refreshInterval);
    //       }
    //     });
  }

  formatDate(date: string): string {
    return formatDate(date, "dd-MM-YYYY HH:mm", "ro-RO", "Europe/Bucharest");
  }

  formatBytes(bytes: number): string {
    return formatBytes(bytes);
  }

  formatTimeInMiliseconds(ms: number): string {
    return formatTimeInMiliseconds(ms);
  }

  getDifferenceInMinutes(ms: number): number {
    return Math.floor(ms / 60000);
  }

  hasMinutesPassedSince(date: Date | undefined, minutes: number): boolean {
    if (!date) {
      return false;
    }
    return hasMinutesPassedSince(date, minutes);
  }

  calculatePercentage(value: number, total: number): number {
    if (total === 0) {
      return 0;
    }

    return (value / total) * 100;
  }

  getSearchStructure() {
    console.log(this.filtersForm.value, "FORM VALUE");
    return Object.entries({
      "id[]": this.filtersForm.value.lockerIdentifiers,
      isActive: !!this.filtersForm.value.isActive,
      isConfigured: !!this.filtersForm.value.isConfigured,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize
    })
    .filter(([_, value]) => value?.length || !!value)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_GetLockerListDataParams] = value;
      return acc;
    }, {} as CBox_GetLockerListDataParams);
  }

  override peekData(data: CBox_GetAdminLockerListDataItem[]): void {
    for (const locker of data) {
      const isOldHeartbeat = this.hasMinutesPassedSince(locker.heartbeat?.lastPingAt, 2);
      const memoryFreePercentage = this.calculatePercentage(locker?.heartbeat?.data?.memory?.available || 0, locker?.heartbeat?.data?.memory?.total || 1);
      const storageFreePercentage = this.calculatePercentage(locker?.heartbeat?.data?.storage?.available || 0, locker?.heartbeat?.data?.storage?.total || 1);
      this.lockerExtras.update((extras) => {
        extras[locker.externalIdentifier] = {
          isOldHeartbeat,
          memoryFreePercentage,
          storageFreePercentage
        };

        return extras;
      })
    }
  }

  isEmptyObject(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0;
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }
}