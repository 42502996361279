import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-profile-admin-impersonate',
  templateUrl: './cbox-profile-admin-impersonate.component.html',
  styleUrls: ['./cbox-profile-admin-impersonate.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule
  ]
})

export class CBoxProfileAdminImpersonateComponent implements OnInit {
  constructor(
    private api: ApiService,
    private toastService: ToastService) { }

  ngOnInit(): void {
    this.fetchUsers();
  }

  private fetchUsers(): void {
    this.api.get("backend/users/list").subscribe(() => {

    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }
}