import { ChangeDetectorRef, Component, DestroyRef, Inject, InjectionToken, Injector, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CBox_GetLockerBaseData, CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { debounceTime, first } from "rxjs";
import { LockersService } from "src/services/lockers/lockers.service";
import { ProfileService } from "src/services/profile/profile.service";
import { SessionService } from "src/services/session/session.service";
import { CBox_AdminGetProcedureDataResponse, CBox_AdminGetProcedureListData, CBox_AdminGetProcedureListDataItem } from "@server/services/cbox/public/api/v1/resources/internal/procedure/types";
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";
import { CBox_PublicPaginatedResponse, CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ButtonModule } from "primeng/button";
import { TableModule, TablePageEvent, TableRowCollapseEvent, TableRowExpandEvent } from "primeng/table";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { ProgressBarModule } from "primeng/progressbar";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { lockerCleanAddress } from "src/helpers/functions";
import { DropdownModule } from "primeng/dropdown";
import { SkeletonModule } from "primeng/skeleton";
import { MatDialog } from "@angular/material/dialog";
import { CBoxProfilePickupRequestInfoComponent } from "app/profile/requests/pickup/dialogs/info/cbox-profile-pickup-request-info.component";
import { TableWithFilters } from "app/shared/table-with-filters";

@Component({
  selector: "app-cbox-profile-admin-locker-procedures-list",
  templateUrl: "./cbox-profile-admin-locker-procedures-list.component.html",
  styleUrls: ["./cbox-profile-admin-locker-procedures-list.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    ReactiveFormsModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ProgressBarModule,
    MultiSelectModule,
    DropdownModule,
    SkeletonModule
  ]
})

export class CBoxProfileAdminLockerProceduresListComponent extends TableWithFilters<CBox_AdminGetProcedureListDataItem[], CBox_AdminGetProcedureListData> implements OnInit {

  filtersForm: FormGroup;
  dataFetchUrl = "internal/procedures";
  localStorageFiltersKey = "admin/procedures-list/filters";
  localStorageFiltersToSave = ["pageSize"];
  sessionFiltersKey = "admin/procedures-list/filters";
  sessionFiltersToSave = ["page", "id", "type"];
  proceduresData = signal<Record<string, CBox_AdminGetProcedureDataResponse>>({});
  fetchingProcedureData = signal<Record<string, true>>({});
  expandedRows = signal<Record<string, boolean>>({});
  defaultFilters = {
    page: 1,
    pageSize: 100,
    id: [],
    type: undefined
  };

  procedureTypes = signal([
    {
      label: "Toate",
      value: undefined
    },
    {
      label: "PickUp",
      value: "USER_PICK_UP"
    },
    {
      label: "DropOff",
      value: "USER_DROP_OFF"
    },
    {
      label: "System",
      value: "SYSTEM"
    },
    {
      label: "System update",
      value: "SYSTEM_UPDATE"
    },
    {
      label: "Agent operation",
      value: "AGENT_OPERATION"
    },
    {
      label: "Unknown",
      value: "UNKNOWN"
    },
  ]);

  constructor(
    inj: Injector,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      super(inj);
      this.filtersForm = this.fb.group({
        page: [1],
        pageSize: [100],
        id: [[]],
        type: [undefined],
      });
    }

  ngOnInit(): void {
    this.profileService.setTitle("Listă evenimente locker");
  }

  patchFilters(filters: {[key: string]: unknown}): void {
    this.filtersForm.patchValue({
      ...filters
    });
  }

  expandRow(procedure: TableRowExpandEvent): void {
    this.expandedRows.update(rows => {
      return {
        ...rows,
        [procedure.data.uniqueProcedureId]: true
      };
    })
    this.fetchProcedureData(procedure.data);
  }

  collapseRow(event: TableRowCollapseEvent): void {
    this.proceduresData.update(proceduresData => {
      delete proceduresData[event.data.uniqueProcedureId];
      return proceduresData;
    });
  }

  openOrderInfo(type: "PICK_UP" | "DROP_OFF", responseId: string): void {
    switch (type) {
      case "PICK_UP":
        this.openPickUpRequestInfo(responseId);
        break;

      default:
        console.warn("Unknown procedure type", type);
        break;
    }
  }

  private openPickUpRequestInfo(responseId: string) {
    const dialog = this.dialog.open(CBoxProfilePickupRequestInfoComponent, {
      data: {
        responseId
      },
      minWidth: "min(1200px, 100%)",
      autoFocus: false
    });

    dialog.afterClosed().pipe(first()).subscribe(() => {
      this.loading.set(true);
      this.fetchData();
    });
  }

  async init(): Promise<void> {}

  private fetchProcedureData(procedure: CBox_AdminGetProcedureListDataItem): void {
    this.fetchingProcedureData.update(fetchingProcedureData => {
      return {
        ...fetchingProcedureData,
        [procedure.uniqueProcedureId]: true
      };
    });
    this.api.get<CBox_PublicSuccessResponse<CBox_AdminGetProcedureDataResponse>>("backend/internal/procedures/" + procedure.uniqueProcedureId).subscribe((response) => {
      this.proceduresData.update(proceduresData => {
        return {
          ...proceduresData,
          [procedure.uniqueProcedureId]: response.data
        };
      });
      this.fetchingProcedureData.update(fetchingProcedureData => {
        delete fetchingProcedureData[procedure.uniqueProcedureId];
        return fetchingProcedureData;
      });
    }, (e: HttpErrorResponse) => {
      this.fetchingProcedureData.update(fetchingProcedureData => {
        delete fetchingProcedureData[procedure.uniqueProcedureId];
        return fetchingProcedureData;
      });
      this.expandedRows.set({});
      handlePublicApiError(e, this.toastService);
    });
  }

  getSearchStructure(): CBox_AdminGetProcedureListData {
    return Object.entries({
      'lockerIdentifiers[]': this.filtersForm.value.id,
      page: +this.filtersForm.value.page,
      pageSize: +this.filtersForm.value.pageSize,
      // "procedureTypes[]: [{
      //   type: this.filtersForm.value.type
      // }]
    })
    .filter(([_, value]) => value?.length || !!value || value != null)
    .reduce((acc, [key, value]) => {
      acc[key as keyof CBox_AdminGetProcedureListData] = value;
      return acc;
    }, {} as any);
  }
}