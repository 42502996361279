<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Generare raport</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="reportForm" class="grid">
    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-2">Dată început</span>
      <p-calendar
        inputId="interval"
        styleClass="w-full"
        dateFormat="dd/mm/yy"
        formControlName="startDate"
        appendTo="body"
        [showIcon]="true"
        [showTime]="true"
        [panelStyle]="{
          'height': '547px'
        }"
      ></p-calendar>
    </div>
    @let startDate = reportForm.get('startDate');
    @let startDateErrors = startDate?.errors;
    @if (startDate?.dirty) {
      @if (startDateErrors) {
        <small class="text-red-500">Vă rugăm să selectați o dată de început</small>
      }
    }

    <div class="col-12 sm:col-6">
      <span class="font-bold block mb-2">Dată sfârșit</span>
      <p-calendar
        inputId="interval"
        styleClass="w-full"
        dateFormat="dd/mm/yy"
        formControlName="endDate"
        appendTo="body"
        [showIcon]="true"
        [showTime]="true"
        [panelStyle]="{
          'height': '547px'
        }"
      ></p-calendar>
    </div>
    @let endDate = reportForm.get('endDate');
    @let endDateErrors = endDate?.errors;
    @if (endDate?.dirty) {
      @if (endDateErrors) {
        <small class="text-red-500">Vă rugăm să selectați o dată de sfârșit</small>
      }
    }

    <div class="col-12">
      <span class="font-bold block mb-1">Lockere</span>
      <p-multiSelect
        styleClass="w-full"
        formControlName="lockers"
        optionLabel="name"
        optionValue="id"
        styleClass="w-full"
        appendTo="body"
        placeholder="Selectează lockere"
        [showClear]="true"
        [options]="lockers()"
        [loading]="!lockers()"
      />
      @let lockersField = reportForm.get('lockers');
      @let lockersErrors = lockersField?.errors;
      @if (lockersField?.dirty) {
        @if (lockersErrors) {
          <small class="text-red-500">Vă rugăm să selectați minim un locker</small>
        }
      }
    </div>

    <div class="flex gap-2 col-12">
      <p class="m-0">Tipul raportului:</p>
      <p-checkbox
        label="PDF"
        value="PDF"
        formControlName="type"
        [ngClass]="{
          'ng-invalid': reportForm.get('type')?.invalid,
          'ng-dirty': reportForm.get('type')?.invalid,
          'ng-touched': reportForm.touched
        }"
      ></p-checkbox>

      <p-checkbox
        label="Excel"
        value="XLSX"
        formControlName="type"
        [ngClass]="{
          'ng-invalid': reportForm.get('type')?.invalid,
          'ng-dirty': reportForm.get('type')?.invalid,
          'ng-touched': reportForm.touched
        }"
      ></p-checkbox>
    </div>

    <!-- <div class="col-12">
      <p-checkbox
        label="Trimite raportul pe email"
        formControlName="sendOnMail"
        [binary]="true"
      ></p-checkbox>
    </div> -->

    @if (reportForm.get('sendOnMail')?.value) {
      <h3 class="col-12 m-0">Email-uri</h3>
      @for (email of emails.controls; track $index) {
        <div class="col-12 sm:col-6">
          <span class="font-bold block mb-1">Email {{$index + 1}}</span>
          <input
            id="awb"
            class="w-full"
            [formControl]="getEmailControlAt($index)"
            pInputText
            [ngClass]="{
              'ng-invalid': emails.at($index).get('awb')?.invalid,
              'ng-dirty': emails.at($index).get('awb')?.invalid,
              'ng-touched': emails.at($index).touched
            }"
          >
        </div>
      }
      @if (emails.length < 10) {
        <div class="col-12">
          <button
            pButton
            label="Adaugă email"
            class="w-full"
            (click)="addEmail()"
          ></button>
        </div>
      }
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex align-items-center gap-2">
    <button
      pButton
      label="Renunță"
      [text]="true"
      mat-dialog-close
    ></button>
    <button
      pButton
      label="Generează raport"
      icon="pi pi-file-plus"
      iconPos="right"
      type="submit"
      (click)="generateReport()"
    ></button>
  </div>
</mat-dialog-actions>
@if (generating()) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}