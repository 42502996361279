<p-button
  size="small"
  label="Crează modul"
  icon="pi pi-plus"
  (click)="createModule()"
  class="w-32"
></p-button>

<form [formGroup]="filtersForm" class="grid align-items-end mt-2">
  <div class="col-12 md:col-4 xl:col-3">
    <span class="font-bold block mb-1">Serie modul</span>
    <p-iconField class="w-full" iconPosition="right">
      @if (filtersForm.get('serial')?.value?.length > 0) {
        <p-inputIcon
          styleClass="pi pi-times cursor-pointer"
          (click)="patchFilters({
            serial: ''
          })"
        />
      }
      <input
        pInputText
        class="w-full"
        placeholder="Serie modul"
        formControlName="serial"
      />
    </p-iconField>
  </div>

  <div class="col-12 md:col-4 xl:col-3">
    <span class="font-bold block mb-1">Status modul</span>
    <p-dropdown
      optionLabel="label"
      optionValue="id"
      styleClass="w-full"
      formControlName="filter"
      [options]="moduleTypes()"
    ></p-dropdown>
  </div>

  <div class="col-12 md:col-4 xl:col-3">
    <span class="font-bold block mb-1">Locker</span>
    <p-dropdown
      showClear="true"
      filter="true"
      formControlName="lockerIdentifier"
      optionLabel="name"
      optionValue="id"
      placeholder="Selectați un locker"
      styleClass="w-full"
      appendTo="body"
      [options]="lockers()"
      [loading]="!lockers()"
      (onClear)="filtersForm.get('filter')?.enable()"
    />
  </div>

  <!-- <div class="col-12 lg:col-3">
    <p-checkbox
      label="Doar noi"
      formControlName="includeNew"
      class="mb-2"
      [binary]="true"
    ></p-checkbox>
  </div> -->
</form>

<p-table
  styleClass="p-datatable-sm w-full mt-2"
  paginatorPosition="both"
  currentPageReportTemplate="{first} - {last} din {totalRecords} module"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [value]="data()"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[25, 50, 100]"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr>
        <td colspan="8">
          <p-progressBar
            mode="indeterminate"
            styleClass="w-full"
            [style]="{ height: '6px' }"
          ></p-progressBar>
        </td>
      </tr>
    }
    <tr>
      <th>Serie</th>
      <th>Tip</th>
      <th>Descriere</th>
      <th>Locker</th>
      <!-- <th></th> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-module>
    <tr class="hover:surface-100 cursor-pointer" (click)="openModuleInfo(module)">
      <td>{{ module.serial }}</td>
      <td>{{ module.type.name }}</td>
      <td>{{ module.type.description }}</td>
      <td>
        @if (module.isAttached) {
          <div
            class="flex flex-column w-max"
            (click)="$event.stopPropagation(); patchFilters({ lockerIdentifier: module.locker.identifier })"
          >
            <small class="text-gray-400">#{{ module.locker.identifier }}</small><br>
            <b>{{ module.locker.name }}</b>
          </div>
        } @else {
          <p>Neatașat</p>
        }
      </td>
      <!-- <td>
        <div class="card flex justify-content-center">
          <p-menu
            styleClass="w-15rem"
            #agentMenu
            appendTo="body"
            [popup]="true"
            [model]="moduleMenuItems()"
          />
          <p-button
            size="small"
            icon="pi pi-ellipsis-v"
            [plain]="true"
            [text]="true"
            [rounded]="true"
            (click)="$event.stopPropagation(); selectedModule.set(module); agentMenu.toggle($event)"
          ></p-button>
        </div>
      </td> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">
        <p class="text-gray-400 text-center text-lg font-bold">Nu exista module.</p>
      </td>
    </tr>
  </ng-template>
</p-table>